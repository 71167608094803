import { Injectable, OnDestroy, signal } from '@angular/core';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { setGraphSidebarOptionsForGraph } from './graph-sidebar-options.actions';
import { selectSidebarOptionsForGraph } from './graph-sidebar-options.selectors';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  GraphId,
  GraphType,
  GraphTypes,
  SelectionDisplayOption,
  SelectionDisplayType,
} from '../../ngs/ngs-graphs/ngs-graphs.model';

@Injectable({
  providedIn: 'root',
})
export class GraphSidebarOptionsService {
  lastGraph = signal<GraphId>('noGraph');
  lastOptions = signal<Record<string, unknown>>({});
  lastSelectionDisplayType = signal<SelectionDisplayType>(SelectionDisplayOption.AUTO);

  constructor(private store: Store<AppState>) {}

  getLastCachedGraph(): GraphType {
    return (
      Object.values(GraphTypes).find((graph) => graph.id === this.lastGraph()) ?? GraphTypes.None
    );
  }
  getCachedOptions(graph?: string): Record<string, any> {
    if (graph && this.lastGraph() !== graph) {
      return {};
    }
    return this.lastOptions() ?? {};
  }

  setLastCachedGraph(graph: GraphId) {
    this.lastGraph.set(graph);
  }

  setLastSelectionDisplayType(type: SelectionDisplayType) {
    this.lastSelectionDisplayType.set(type);
  }

  getLastSelectionDisplayType(): SelectionDisplayType {
    return this.lastSelectionDisplayType();
  }

  setCachedOptions(graph: GraphId, options: Record<string, any>) {
    this.lastGraph.set(graph);
    this.lastOptions.set(options);
  }

  getSavedOptions(id: string): Observable<Record<string, any>> {
    return this.store.select(selectSidebarOptionsForGraph(id)).pipe(
      filter((x) => !!x),
      take(1),
    );
  }

  setSavedOptions(id: string, options: Record<string, any>) {
    this.store.dispatch(setGraphSidebarOptionsForGraph({ id, options }));
  }
}
